<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">

            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Categories</h4>
                  <div class="">
                    <router-link :to="{ name: 'createcategory' }">
                      <button class="btn btn-default custom_btn custon_orange">
                        <i class="fa fa-plus"></i> New Category
                      </button>
                    </router-link>
                  </div>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchCategories()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." class="form-control">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <select class="form-control mt-1" @change="searchCategories()" v-model="categoryStatus">
                        <option value="">--Select status --</option>
                        <option value="1">Published</option>
                        <option value="2">Unpublished</option>
                        <option value="3">Archived</option>
                        <option value="4">Trashed</option>
                        <option value="5">All</option>
                      </select>
                    </div>

                  </div>
                </div>
              </div>
              <hr>
            </div>

            <div class="col-lg-12">
              <!--Filters-->
              <div class="filters" id="filter_bar" style="display:none">
                <div class="" v-if="adminRoles.includes(user.role.id)">
                  <span class="btn-filter mt-1">
                    <input type="checkbox" id="uncheck" @click="checkItems('uncheck')" indeterminate=true>
                  </span>
                  <button class="btn-filter mr-1 mt-1">
                    {{categorySelected >1 ? categorySelected + ' categories': categorySelected + ' category'}} selected
                  </button>

                  <button class="btn-filter mr-2 mt-1" @click="bulkStatusUpdate('publish', 1)">
                    <i class='fa fa-check' style="color:green;"></i>
                    <span v-if="methodSpinner == 'publish'"><span v-html="spinner"></span> </span> <span v-else>
                      Publish</span> </button>

                  <button class="btn-filter mr-2 mt-1" @click="bulkStatusUpdate('unpublish', 2)">
                    <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                    <span v-if="methodSpinner == 'unpublish'"><span v-html="spinner"></span></span> <span v-else>
                      Unpublish</span> </button>

                  <button class="btn-filter mr-2 mt-1" @click="bulkStatusUpdate('trash', 4)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                    <span v-if="methodSpinner == 'trash'">
                      <span v-html="spinner"></span>
                    </span>
                    <span v-else> Trash</span>
                  </button>
                  <button class="btn-filter mr-2 mt-1" @click="bulkStatusUpdate('archive', 3)">
                    <i class="fa fa-archive" aria-hidden="true"></i> <span v-if="methodSpinner == 'archive'">
                      <span v-html="spinner"></span> </span> <span v-else> Archive</span> </button>

                </div>

              </div>
              <!--Filters-->

              <div class="dash_card_body pt-0">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">
                          <input type="checkbox" id="check_all" @click="checkItems('check')">
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Menus</th>
                        <th scope="col">Status</th>
                        <th scope="col">Items</th>

                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="(category) in categories.data" :key="category.id" draggable="true" @drop="onDrop($event,category)" @dragenter.prevent @dragover.prevent @dragstart="startDrag($event,category)">
                        <td>
                          <input type="checkbox" :id="'category_' + category.id" @click="selectCategories(category.id)">
                        </td>
                        <td>
                          <router-link :to="{ name: 'updatecategory', params: { categoryid: category.id } }">
                            <span class="title_text">{{ category.title }}</span>
                          </router-link>
                        </td>
                        <td>
                          {{ category.menus.join(", ") }}
                        </td>
                        <td>
                          <div class="btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'" type="button">
                              <span v-if="category.status == 1">
                                <i class='fa fa-check' style="color:green;"></i>
                              </span>
                              <span v-else-if="category.status == 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                              <span v-else-if="category.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="adminRoles.includes(user.role.id)" type="button" class="btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle="dropdown" :id="'dropdownMenuButton' + category.id">
                              <span class="caret"></span>
                              <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton' + category.id">
                              <a class="dropdown-item" @click="updateStatsPerItem(category.id, 1)" href="#">Publish</a>
                              <a class="dropdown-item" @click="updateStatsPerItem(category.id, 2)" href="#">Unpublish</a>
                              <a class="dropdown-item" @click="updateStatsPerItem(category.id, 3)" href="#">Archive</a>
                              <a class="dropdown-item" @click="updateStatsPerItem(category.id, 4)" href="#">Trash</a>

                            </div>
                          </div>

                        </td>
                        <td>
                          <span v-if="category.item_names != null">
                            {{ category.item_names.length }} Item<span v-if="category.item_names.length > 1">s</span>
                          </span>
                          <span v-else> No Items Available</span>
                        </td>

                      </tr>

                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <!-- {{totalPages}} -->
                          <div id="pagination" >
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/pages/pagination/pagination.vue';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            categorySelected: 0,
            categories: [],
            loading: false,
            page: 1,
            totalPages: 10,
            totalRecords: 0,
            categoryStatus: '',
            recordsPerPage: this.$store.state.categoryRecordsPerPage,
            methodSpinner: null,
            spinner: "<i class='fa fa-spinner fa-spin '></i>",
            search: '',
            debounce: null,
            categoryIds: []
        }
    },
    mounted: async function () {
        this.getCategories();
        this.categoryIds = [];
    },
    methods: {
        startDrag(event, cat) {
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.setData('itemId', cat.id);
            // evt.dataTransfer.setData('itemID', item.id)    
        },
        async onDrop(event, cat) {
            const catid = event.dataTransfer.getData('itemId')
            try {
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const url = `${this.baseUrl}/menu_category/position`;
                const payload = {
                    'merchant_id': parseInt(this.merchantID),
                    'parent_id': '',
                    'operation_type': 0,
                    'update': {
                        'id': catid,
                        'position': cat.position
                    }
                }
                const response = await this.axios.patch(url, payload, config);
                if (response.status == 200) {
                    this.getCategories();
                }

            } catch (error) {
                console.log('error')
            }
        },
        searchCategories() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getCategories();
            }, 600)
        },
        bulkStatusUpdate(method, status) {
            this.updateStatus(this.categoryIds, method, status);
        },
        updateStatsPerItem(catId, status) {
            const catIdsArr = [];
            if (catIdsArr.includes(catId) === false) {
                catIdsArr.push(catId)
            }
            this.updateStatus(catIdsArr, '', status)
        },
        updateStatus(catIdsArr, method, status) {
            if (catIdsArr.length > 0) {
                this.methodSpinner = method;
                if (method === 'publish' && status === false) {
                    this.methodSpinner = 'unpublish'
                }
                const payload = {
                    "merchant_id": parseInt(this.merchantID),
                    "ids": catIdsArr,
                    "status": status
                };
                const url = this.baseUrl + "/menu_category/status";
                this.axios.patch(url, payload, this.apiRequestConfig()).then((response) => {
                    this.categoryIds = [];
                    if (response.status === 200) {
                        toast.success('updated successfully')
                        this.methodSpinner = null;
                        this.getCategories();
                        // 
                    }
                }).catch((error) => {
                    this.categoryIds = [];
                    this.methodSpinner = null;
                    if (error.response.status === 401) {
                        this.$router.push({
                            name: 'login'
                        });
                    }
                });
            }
        },
        checkItems(type) {
            document.querySelector('#uncheck').indeterminate = true;
            if (type === 'check') {
                if (document.getElementById('check_all').checked) {
                    document.getElementById('filter_bar').style.display = 'block'
                    this.categorySelected = 0;
                    for (const category of Object.values(this.categories.data)) {
                        this.categorySelected += 1;
                        document.getElementById('category_' + category.id).checked = true
                        if (this.categoryIds.includes(category.id) === false) {
                            this.categoryIds.push(category.id);
                        }
                    }
                } else {
                    document.getElementById('filter_bar').style.display = 'none'
                    for (const category of Object.values(this.categories.data)) {
                        document.getElementById('category_' + category.id).checked = false
                    }
                    this.categoryIds = []
                }

            } else {
                document.getElementById('filter_bar').style.display = 'none'
                document.getElementById('check_all').checked = false
                for (const category of Object.values(this.categories.data)) {
                    document.getElementById('category_' + category.id).checked = false
                }
                this.categoryIds = []
            }

        },
        selectCategories(catId) {
            if (document.getElementById('category_' + catId).checked) {
                if (this.categoryIds.includes(catId) === false) {
                    this.categoryIds.push(catId);
                }
                document.getElementById('filter_bar').style.display = 'block'
            } else {
                if (this.categoryIds.indexOf(catId) !== -1) {
                    this.categoryIds = this.categoryIds.filter((x) => x !== catId);
                }
                for (const category of Object.values(this.categories.data)) {
                    if (category.id != catId && document.getElementById('category_' + category.id).checked) {
                        document.getElementById('filter_bar').style.display = 'block'
                        break;
                    } else {
                        document.getElementById('filter_bar').style.display = 'none'
                    }
                }
            }
            // increasing count
            this.categorySelected = 0;
            for (const category of Object.values(this.categories.data)) {
                if (document.getElementById('category_' + category.id).checked) {
                    this.categorySelected += 1;
                }
            }
        },
        async getCategories() {
            this.loading = true;
            // const url = `${this.baseUrl}//menu_category?page=${this.page}&merchant_id=${this.merchantID}&size=${this.recordsPerPage}&query=${this.search}`;
            let url = `${this.baseUrl}//menu_category`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            url.searchParams.set('query', this.search);
            url.searchParams.set('status', this.categoryStatus);
            url.searchParams.set('merchant_id', this.merchantID);

            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    this.categories = response.data
                    this.totalPages = Math.ceil(response.data.total / this.recordsPerPage)
                    this.totalRecords = response.data.total_count
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => this.loading = false)
        },
        onPageChange(page) {
            this.categoryIds = []
            document.getElementById('check_all').checked = false;
            document.getElementById('filter_bar').style.display='none';
            this.page = page
            this.getCategories();
        },
        onChangeRecordsPerPage() {
            const payload = {
                "type":"category",
                "size":this.recordsPerPage
            }
            this.$store.commit("setPageLimit",payload)
            // setPageLimit
            this.getCategories();
            // ds
        }
    },
    mixins: [ReusableDataMixins]
}
</script>
